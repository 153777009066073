import React from "react"
import { Col, Container, Row } from "reactstrap"

export const AboutReha = () => {
  return (
    <Container className="aboutRega--container">
      <Row>
        <Col sm={6}>
          <h1 className="font-size-24 font-weight-700 color-black">
            Reha-Help to miejsce w którym zajmiemy się Tobą
          </h1>
          <p className="font-size-24 ">
            i Twoim ciałem holistycznie. Stosowane terapie dobierane są
            indywidualnie, w zależności od potrzeb i stanu zdrowia pacjenta.
            Pomagamy pozbyć się bólu o podłożu fizycznym jak i emocjonalnym.
            Pozwalamy wrócić do sprawności po urazach oraz w przypadku chorób
            przewlekłych. Metody leczenia oparte są na pracy układu nerwowego
            czyli głównego „komputera” kontrolującego prace naszego ciała, co
            daje szybkie i długotrwałe efekty.s
          </p>
        </Col>

        <Col
          sm={6}
          className="d-none d-lg-flex justify-content-center flex-column"
        >
          <img src="/images/whiteLogo.svg" />
        </Col>
      </Row>
    </Container>
  )
}
