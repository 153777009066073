import React from "react"
import { Col, Container, Row } from "reactstrap"

export const Wiliam = () => {
  return (
    <div className="william__container" id="william">
      <Container>
        <Row>
          <Col sm={4} lg={4} className="text-center">
            <img
              src="/images/william.jpg"
              className="william__container__content--portret"
            />
          </Col>
          <Col sm={8} lg={8} className="william__container__content">
            <img
              width="30px"
              src="/images/dots.svg"
              className="william__container__content--dots d-none d-lg-block"
            />
            <div>
              <p className="william__container__content--top">
                „Nasze ciała są naszymi ogrodami nasza wola to nasi ogrodnicy.”
              </p>
              <p className="lobster william__container__content--bottom">
                ~ William Shakespeare
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
