import React from "react"
import { Col, Container, Row } from "reactstrap"

export const Discorders = () => {
  const texts = [
    "Ból kręgosłupa",
    "Traumy emocjonalne",
    "Stany pourazowe",
    "Zmiany zwyrodnieniowe",
    "Nawracające zapalenie pęcherza",
    "Przeciążenia",
    "Łokieć golfisty/tenisisty",
    "Bolesne miesiączki",
    "Rwa kulszowa",
    "Ograniczenia ruchomości stawów",
    "Dolegliwości ze strony układu trawiennego",
    "Chroniczny stres",
    "Problemy z układem moczowym",
    "Bóle stawów",
    "Bóle głowy",
  ]

  return (
    <Container className="discorders--containers">
      <p className="text-center font-size-24 font-weight-700 color-black">
        Leczymy następujące schorzenia:
      </p>
      <Row>
        {texts.map(str => {
          return (
            <Col
              sm={4}
              className="font-size-24 font-color-black discorders--containers--col"
            >
              <img
                style={{ margin: "0 .5rem" }}
                src="/images/circle.svg"
                width="40px"
              />
              {str}
            </Col>
          )
        })}
      </Row>
      <div className="discorders--containers--buttons">
        <a className="discorders--containers--btn font-size-24" href="">
          Kontakt
        </a>
        <a className="discorders--containers--btn font-size-24" href="">
          Cennik
        </a>
      </div>
    </Container>
  )
}
