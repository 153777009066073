import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { Navbar } from "./navbar"

export const MainHeader = () => {
  const [width, setWidth] = useState()
  console.log(width)

  const isDesktop = width >= 992

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  return (
    <Container fluid className="mainHeader">
      <Container className="mainHeader__content">
        <Navbar pageName="main" />
        {isDesktop ? (
          <img src="/images/blackLogo.svg" />
        ) : (
          <img src="/images/logoMobile.svg" />
        )}
        <a href="#william" className="mainHeader__content--button">
          O firmie
        </a>
      </Container>
    </Container>
  )
}
