import React from "react"
import { Col, Container, Row } from "reactstrap"

export const Schoolers = () => {
  const texts = [
    "Integrated Soft Touch Techniques, Trigger Points I",
    "P-DTR Proprioceptive- Deep Tendon Reflex Master Class",
    "Kinesiology Taping",
    "Integrated Soft Touch Techniques, Trigger Points II",
    "The Barral Institute, Visceral Manipulation I",
    "Holistic Scanning Intro",
    "P-DTR Proprioceptive- Deep Tendon Reflex Basic",
    "The Barral Institute, Visceral Manipulation II",
    "Holistic Scanning Emotions",
    "P-DTR Proprioceptive- Deep Tendon Reflex Intermidiate",
    "The Barral Institute, Visceral Manipulation III",
    "Holistic Scanning Body",
    "P-DTR Proprioceptive- Deep Tendon Reflex Advanced",
    "Mulligan Concept A",
  ]

  return (
    <Container className="discorders--containers">
      <p className="text-center font-size-24 font-weight-700 color-black">
        Oto spis szkoleń i Kursów które do tej pory odbyłam:
      </p>
      <Row>
        {texts.map(str => {
          return (
            <Col
              sm={4}
              className="font-size-24 font-color-black discorders--containers--col"
            >
              <img
                style={{ margin: "0 .5rem" }}
                src="/images/circle.svg"
                width="40px"
              />
              {str}
            </Col>
          )
        })}
      </Row>
      <div className="discorders--containers--buttons">
        <a className="discorders--containers--btn font-size-24" href="/contact">
          Kontakt
        </a>
      </div>
    </Container>
  )
}
