import * as React from "react"

import Layout from "../components/layout"
import {MainHeader} from "../components/mainHeader";
import {Wiliam} from "../components/wiliam";
import {AboutReha} from "../components/aboutReha";
import {Footer} from "../components/footer";
import {Discorders} from "../components/discorders";
import {AnnaKulach} from "../components/annaKulach";
import {Schoolers} from "../components/schoolers";

const IndexPage = () => (
  <Layout>
    <MainHeader/>
    <Wiliam/>
    <AboutReha/>
    <Discorders/>
    <AnnaKulach/>
    <Schoolers/>
    <Footer/>
  </Layout>
)

export default IndexPage
