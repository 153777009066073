import React from 'react'
import {Col, Container, Row} from "reactstrap";

export const AnnaKulach = () => {
  return <Container className="avatar--container">
    <Row>
      <Col sm={6}>
        <h1 className="font-size-24 font-weight-700">Anna Kułach</h1>
        <p className="font-size-24 ">
          Jestem magistrem fizjoterapii ,ukończyłam Akademię Wychowania Fizycznego w Krakowie. Pracę w zawodzie fizjoterapeuty zaczęłam w Szpitalu Rehabilitacyjnym w Zakopanem, obecnie prowadzę własną praktykę zawodową. Bardzo cenię sobie możliwość indywidualnej pracy z pacjentem, ze względu na fakt iż każdy człowiek jest inny, ma swoją historię, a co za tym idzie podłoże dolegliwości z którymi się boryka jest bardzo zróżnicowane. Celem nadrzędnym w mojej pracy jest bezbolesne, możliwie jak najszybsze przywrócenie zdrowia pacjentom, usunięcie bólu oraz powrót do aktywności fizycznej. Aby moja praca była efektywna jestem w trakcie poszerzania swojej wiedzy i umiejętności.
        </p>
      </Col>
      <Col sm={6} className="text-center">
        <img width="400px" height="400px" src="/images/avatar.jpg" className="avatar--container--img image-shadow"/>
      </Col>

    </Row>
  </Container>
}